/* istanbul ignore file */ //AMBIENT: "development" OR "production"
let Constants = {
  AMBIENT: "production", //automatically changed on package.json in react build script
  RELEASE: "0.1",
  SENTRY_DSN:
    "https://45105330c5d94ef08b5ead361712df68@o46221.ingest.sentry.io/6128833",
  API_TOKEN: "9097387a3587deb16bd2c322aff134fd45979067",
  BASE_URL_dev: "http://localhost:8000",
  BASE_URL_prod: "https://backend2.ticsocial.com.co",
  API_PARAMETERS: "/api/core/parameters/",
  LANGUAGES: ["es", "en"],
  GOOGLE_PLACES_API:
    "https://maps.googleapis.com/maps/api/place/textsearch/json?query=restaurants%20in%20Sydney",
  GOOGLE_RECAPTCHA_PUBLIC_KEY: "6LdbFJUcAAAAAAolg3L-wUye2Vs05JuOUrxxzAEQ",
  GOOGLE_MAPS_API_KEY: "AIzaSyDI_z31iZ-sjLz4prV35Pcy6p-tTSZzY88",
};

if (Constants.AMBIENT === "production") {
  Constants.BASE_URL = Constants.BASE_URL_prod;
} else if (Constants.AMBIENT === "development") {
  Constants.BASE_URL = Constants.BASE_URL_dev;
}

export default Constants;
