import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import Constants from "./app/constants";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as Sentry from "@sentry/react";
import store from "./app/redux/store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  detectLanguage,
  mapParametersToi18n,
} from "./app/utils/Parameters/parametersFunctions";
const queryClient = new QueryClient();

/* istanbul ignore next */
if (Constants.AMBIENT === "production") {
  Sentry.init({ dsn: Constants.SENTRY_DSN, release: Constants.RELEASE });
}

fetch(Constants.BASE_URL + Constants.API_PARAMETERS, {
  headers: {
    Authorization: "Token " + Constants.API_TOKEN,
  },
}).then((response) => {
  response.json().then((data) => {
    const resources = mapParametersToi18n(data); //maps parameters to i18next format
    const lng = detectLanguage(); //detects initial language
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        initImmediate: false,
        resources,
        lng,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      })
      .then(() => {
        /* istanbul ignore next */
        ReactDOM.render(
          <BrowserRouter>
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </Provider>
          </BrowserRouter>,
          document.getElementById("root")
        );
        /* istanbul ignore next */
        serviceWorker.unregister();
      });
  });
});
