/* istanbul ignore file */

import React, { useEffect, Suspense, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Spinner from "./components/core/Spinner";
import PublicRoute from "./routes/PublicRoute";
import PATHS from "./routes/paths.json";
import Constants from "./constants";
import HomeNavbar from "./components/HomeNavbar/HomeNavbar";
import AOS from "aos";
import "aos/dist/aos.css";

const Error404 = lazy(() => import("./pages/error-pages/Error404"));
/* istanbul ignore next */
const HomePage = lazy(() => import("./pages/public-pages/HomePage/HomePage"));
const ManagementPage = lazy(() =>
  import("./pages/public-pages/ManagementPage/ManagementPage")
);
const ClasificationPage = lazy(() =>
  import("./pages/public-pages/ClasificationPage/ClasificationPage")
);
const CharacterizingPage = lazy(() =>
  import("./pages/public-pages/CharacterizingPage/CharacterizingPage")
);
const BusinessIntelPage = lazy(() =>
  import("./pages/public-pages/BusinessIntelPage/BusinessIntelPage")
);
const ClientsPage = lazy(() =>
  import("./pages/public-pages/ClientsPage/ClientsPage")
);
const GlobalPresencePage = lazy(() =>
  import("./pages/public-pages/GlobalPresencePage/GlobalPresencePage")
);

const CompanyPage = lazy(() =>
  import("./pages/public-pages/CompanyPage/CompanyPage")
);

const TeamPage = lazy(() => import("./pages/public-pages/TeamPage/TeamPage"));
const ContactUsPage = lazy(() =>
  import("./pages/public-pages/ContactUsPage/ContactUsPage")
);
const TermsConditions = lazy(()=> import("./pages/public-pages/TermsConditions/TermsConditionsPage"))

/* istanbul ignore next */
export default function AppRoutes() {
  /* istanbul ignore next */
  useEffect(() => {
    AOS.init({ duration: 800, once: true });
  }, []);
  return (
    <Suspense fallback={<Spinner />}>
      <HomeNavbar />
      <Switch>
        <Route path={PATHS.ERROR_404_PATH} component={Error404} exact />
        <Route path={PATHS.HOME_PATH} component={HomePage} exact />
        <Route path={PATHS.MANAGEMENT} component={ManagementPage} exact />
        <Route path={PATHS.CLASIFICATION} component={ClasificationPage} exact />
        <Route
          path={PATHS.CHARACTERIZING}
          component={CharacterizingPage}
          exact
        />

        <PublicRoute
          restricted={false}
          path={PATHS.CONTACT}
          component={ContactUsPage}
          exact
        />
        <PublicRoute
          restricted={false}
          path={PATHS.BUSINESS_INTELLIGENCE}
          component={BusinessIntelPage}
          exact
        />
        <PublicRoute
          restricted={false}
          path={PATHS.CLIENTS_ALLIES}
          component={ClientsPage}
          exact
        />
        <PublicRoute
          restricted={false}
          path={PATHS.GLOBAL_PRESENCE}
          component={GlobalPresencePage}
          exact
        />
        <PublicRoute
          restricted={false}
          path={PATHS.COMPANY}
          component={CompanyPage}
          exact
        />
        <PublicRoute
          restricted={false}
          path={PATHS.TEAM}
          component={TeamPage}
          exact
        />
        <PublicRoute
          restricted={false}
          patch={PATHS.TERMS_CONDITIONS}
          component={TermsConditions}
          exact
        />
        <Redirect to={PATHS.ERROR_404_PATH} />
      </Switch>
    </Suspense>
  );
}
