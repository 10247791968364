import Constants from "../../constants";

export const mapParametersToi18n = (results) => {
  let parameters = [];
  Constants.LANGUAGES.forEach((language) => {
    parameters.push([language, { translation: {} }]);
  });
  parameters = Object.fromEntries(parameters);
  results.forEach((result) => {
    Constants.LANGUAGES.forEach((language) => {
      parameters[language].translation[result.key] =
        result["value_" + language];
    });
  });
  return parameters;
};

export const detectLanguage = () => {
  let lng = localStorage.getItem("lng");
  if (lng) return lng;
  lng = "es";
  Constants.LANGUAGES.forEach((language) => {
    if (navigator.language.includes(language)) lng = language;
  });
  return lng;
};
