import Constants from "../../constants";
import { getToken } from "../LoginFunctions";
import { request } from "axios";
import { inputValidation } from "../InputValidation/InputValidation";

export const GET = (_url, body = {}, _config = {}, params = {}, rest = {}) => {
  return HTTPRequest("get", _url, body, _config, params, rest);
};

export const POST = (_url, body = {}, _config = {}, params = {}, rest = {}) => {
  return HTTPRequest("post", _url, body, _config, params, rest);
};

export const PATCH = (
  _url,
  body = {},
  _config = {},
  params = {},
  rest = {}
) => {
  return HTTPRequest("patch", _url, body, _config, params, rest);
};

export const PUT = (_url, body = {}, _config = {}, params = {}, rest = {}) => {
  return HTTPRequest("put", _url, body, _config, params, rest);
};

export const UPDATE = (
  _url,
  body = {},
  _config = {},
  params = {},
  rest = {}
) => {
  return HTTPRequest("update", _url, body, _config, params, rest);
};

export const DELETE = (_url, body = {}, _config = {}, params = {}, rest) => {
  return HTTPRequest("delete", _url, body, _config, params, rest);
};

export const HTTPRequest = async (
  method = "get",
  _url,
  body = {},
  _config = {},
  params = {},
  rest = {}
) => {
  let url = _url;
  const token = "Bearer " + getToken();
  let config = { ..._config };
  if (!inputValidation(_url, "url")) {
    url = Constants.BASE_URL + url;
    config = { ...config, headers: { Authorization: token } };
  }
  const avaibleStatus = [200, 201, 202, 203, 204, 205];
  const requestConfig = {
    url,
    data: body,
    ...config,
    method,
    params,
    ...rest,
  };
  return request(requestConfig)
    .then((response) => {
      if (avaibleStatus.includes(response.status)) {
        return Promise.resolve(response);
      } else {
        return Promise.reject("status no avaible" + response.status);
      }
    })
    .catch((error) => {
      return error;
    });
};
