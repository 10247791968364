import React, { useState, useEffect } from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PATHS from "../../routes/paths.json";
import logo from "../../../assets/images/logo-small.png";
import { Button } from "react-bootstrap";
import Constants from "../../constants";

export const navlinks = [
  {
    href: PATHS.HOME_PATH,
    name: "home",
  },
  {
    href: PATHS.OUR_WORK,
    name: "our_work",
    dropdown: [
      {
        href: PATHS.MANAGEMENT,
        name: "management",
      },
      {
        href: PATHS.CLASIFICATION,
        name: "clasification",
      },
      {
        href: PATHS.BUSINESS_INTELLIGENCE,
        name: "business_intelligence",
      },
      {
        href: PATHS.CHARACTERIZING,
        name: "characterizing",
      },
    ],
  },
  {
    href: PATHS.ABOUT_US,
    name: "about_us",
    dropdown: [
      {
        href: PATHS.COMPANY,
        name: "company",
      },
      {
        href: PATHS.TEAM,
        name: "team",
      },
    ],
  },
  {
    href: PATHS.CLIENTS_ALLIES,
    name: "clients_allies",
  },
  {
    href: PATHS.GLOBAL_PRESENCE,
    name: "global_presence",
  },
  {
    href: PATHS.CONTACT,
    name: "contact",
  },
];

export default function HomeNavbar() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [expanded, setExpanded] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -30;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  const logoStyles = {
    width: "150px",
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
    setCurrentLanguage(lng);
  };

  const NavDropdown = ({
    showKey,
    label,
    items,
    onClickItem,
    isLabelUpperCase,
    isItemUpperCase,
    isLink,
  }) => {
    return (
      <Dropdown
        show={showDropdown === showKey}
        onMouseEnter={() => setShowDropdown(showKey)}
        onMouseLeave={() => setShowDropdown(false)}
        className="d-flex align-items-center justify-content-center position-relative flex-column"
      >
        <Dropdown.Toggle
          className="w-100 text-center"
          as={isLink ? Nav.Link : Button}
          variant="outline"
          size="sm"
          id={"dropdown-" + showKey}
        >
          <span>{t(isLabelUpperCase ? label.toUpperCase() : label)}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {items.map((item, index) =>
            isLink ? (
              <Dropdown.Item
                as={Link}
                active={location.pathname === item.href}
                to={item.href}
                key={"dropdown-item-" + index}
                onClick={() => {
                  setExpanded(false);
                  setShowDropdown(false);
                  window.scrollTo(0, 0);
                }}
              >
                {t(item.name)}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                className="text-center"
                key={index}
                onClick={() => {
                  setExpanded(false);
                  onClickItem(item);
                  window.scrollTo(0, 0);
                }}
              >
                {isItemUpperCase ? item.toUpperCase() : item}
              </Dropdown.Item>
            )
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const NavLink = ({ href, label }) => (
    <Nav.Link
      key={href}
      as={Link}
      to={href}
      className="d-flex align-items-center"
      active={location.pathname === href}
      onClick={() => {
        setExpanded(false);
        window.scrollTo(0, 0);
      }}
    >
      <span>{t(label)}</span>
    </Nav.Link>
  );

  return (
    <Navbar
      fixed="top"
      collapseOnSelect
      expand="lg"
      expanded={expanded}
      className="shadow-sm"
      variant="light"
    >
      <Navbar.Brand>
        <HashLink
          onClick={() => {
            setExpanded(false);
            window.scrollTo(0, 0);
          }}
          to="/#cover"
          scroll={scrollWithOffset}
        >
          <img className="img-fluid" style={logoStyles} src={logo} alt="logo" />
        </HashLink>
      </Navbar.Brand>

      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded(!expanded)}
      />

      <Navbar.Collapse>
        <Nav>
          {navlinks.map((navlink) =>
            navlink.dropdown ? (
              <NavDropdown
                label={navlink.name}
                isLink
                items={navlink.dropdown}
                showKey={navlink.name}
                key={"navlink-" + navlink.name}
              />
            ) : (
              <NavLink
                key={"navlink-" + navlink.name}
                label={navlink.name}
                href={navlink.href}
              />
            )
          )}
        </Nav>
        <NavDropdown
          showKey={"trans"}
          isLabelUpperCase
          isItemUpperCase
          label={currentLanguage}
          items={Constants.LANGUAGES.filter((lng) => lng !== currentLanguage)}
          onClickItem={(lng) => changeLanguage(lng)}
        />
      </Navbar.Collapse>
    </Navbar>
  );
}
