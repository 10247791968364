import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./components/core/Navbar";
import Footer from "./components/core/Footer";
import Constants from "./constants";
import PATHS from "./routes/paths.json";

/* istanbul ignore next */
class App extends Component {
  state = {
    isFullPageLayout: true,
  };

  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : "";
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";

    return (
      <div>
        <div className="container-scroller">
          {navbarComponent}
          <div className="container-fluid page-body-wrapper">
            <div className="main-panel">
              <div className="content-wrapper">
                <AppRoutes />
              </div>
              {footerComponent}
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.onRouteChanged();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  onRouteChanged() {
    const fullPageLayoutRoutes = [
      PATHS.HOME_PATH,
      PATHS.CONTACT,
      PATHS.ERROR_404_PATH,
      PATHS.MANAGEMENT,
      PATHS.CLASIFICATION,
      PATHS.CHARACTERIZING,
      PATHS.BUSINESS_INTELLIGENCE,
      PATHS.CLIENTS,
      PATHS.ALLIES,
      PATHS.GLOBAL_PRESENCE,
      PATHS.COMPANY,
      PATHS.TEAM,
      PATHS.TERMS_CONDITIONS
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      const actualPath = this.props.location.pathname;
      const path = fullPageLayoutRoutes[i];
      if (
        actualPath === path ||
        (actualPath.includes(fullPageLayoutRoutes[i]) &&
          path !== PATHS.HOME_PATH)
      ) {
        this.setState({
          isFullPageLayout: true,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
  }
}

export default withRouter(App);
