import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../utils/LoginFunctions";
import PATHS from "./paths.json";
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    // restricted = define si se puede o no acceder a la ruta una vez está logueado
    <Route
      {...rest}
      render={
        /* istanbul ignore next */
        (props) =>
          isLogin() && restricted ? (
            <Redirect to={PATHS.PATIENTS_PATH} />
          ) : (
            <Component {...props} />
          )
      }
    />
  );
};

export default PublicRoute;
