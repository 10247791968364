import { post, get } from "axios";
import Constants from "../constants";

export const setUserInformation = () => {
  getUserInformation()
    .then((res) => {
      const userInformation = JSON.stringify(res);
      localStorage.setItem("userInformation", userInformation);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const login = async (access, refresh) => {
  localStorage.setItem("access", access);
  localStorage.setItem("refresh", refresh);
  await getUserInformation()
    .then((res) => {
      const userInformation = JSON.stringify(res);
      localStorage.setItem("userInformation", userInformation);
      window.location.reload();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const logout = () => {
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
  localStorage.removeItem("userInformation");
  window.location.reload();
};

export const isLogin = () => {
  if (localStorage.getItem("access")) {
    setUserInformation();

    return true;
  }

  return false;
};

export const getToken = () => {
  if (isLogin) {
    const token = localStorage.getItem("access");
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const decode = JSON.parse(window.atob(base64));

    const exp = decode.exp - 3600;
    const timestamp = Math.floor(Date.now() / 1000);

    //Si el token y refresh ya expiraron, se cierra sesión
    if (timestamp >= decode.exp) {
      logout();
      window.location.reload();
    }

    //Si está a 1 hora de expirar, se refresca el token
    if (timestamp >= exp) {
      const url = Constants.BASE_URL + Constants.API_REFRESH;
      const refresh = localStorage.getItem("refresh");

      post(url, {
        refresh: refresh,
      })
        .then((response) => {
          if (response.status === 200) {
            return response.data.access;
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    }

    return token;
  }
  return false;
};

export const getUserInformation = async () => {
  if (isLogin) {
    const token = "Bearer " + getToken();
    const url = Constants.BASE_URL + Constants.API_USERS;
    const config = {
      headers: { Authorization: token },
    };
    const result = await get(url, config).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        return data;
      }
    });
    return result;
  } else {
    return false;
  }
};
