import {
  CHANGE_VALUES,
  GENERAL_ERROR,
  GET_PARAMETERS,
  GET_TEAM_MATES,
  GET_CLIENTS,
  GET_ALLIES,
  GET_CLIENTS_STORIES,
  GET_GLOBAL_PRESENCES,
  GET_TYC_DOCUMENT,
} from "./actionTypes";
import Constants from "../constants";

const initialState = {
  teamMates: [],
  clients: [],
  allies: [],
  clientsStories: [],
  globalPresences: [],
  tycDocument: null,
  status: 0,
};

export default (state = initialState, action) => {
  if (action.type === GENERAL_ERROR)
    return {
      ...state,
      status: action?.payload?.response?.status,
      patient: action?.payload?.response?.data,
    };
  if (action.type === CHANGE_VALUES) return { ...state, ...action.variables };
  if (action.type === Constants.API_MASSIVE_LOAD_PRESIGNED_URLS)
    return { ...state, ...action.variables };
  if ([GET_TEAM_MATES].includes(action.type))
    return {
      ...state,
      status: action.payload.status,
      teamMates: action.payload.data,
    };
  if ([GET_CLIENTS].includes(action.type))
    return {
      ...state,
      status: action.payload.status,
      clients: action.payload.data,
    };
  if ([GET_ALLIES].includes(action.type))
    return {
      ...state,
      status: action.payload.status,
      allies: action.payload.data,
    };
  if ([GET_CLIENTS_STORIES].includes(action.type))
    return {
      ...state,
      status: action.payload.status,
      clientsStories: action.payload.data,
    };
  if ([GET_GLOBAL_PRESENCES].includes(action.type))
    return {
      ...state,
      status: action.payload.status,
      globalPresences: action.payload.data,
    };
  if ([GET_TYC_DOCUMENT].includes(action.type))
    return {
      ...state,
      status: action.payload.status,
      tycDocument: action.payload.data,
    };
  return state;
};
