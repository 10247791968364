import React, { Component, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { logout } from "../../utils/LoginFunctions";
import PATHS from "../../routes/paths.json";
import logo from "../../../assets/images/logo-white-small.png";
import logo_small from "../../../assets/images/unique-logo-white-small.png";
import { GET } from "../../utils/HTTPRequest/HTTPRequest";
import Constants from "../../constants";
import cogoToast from "cogo-toast";

function Navbar(props) {
  const [reportsLength, setReportsLength] = useState(null);
  const [mount, setMount] = useState(true);
  const location = useLocation();
  const { t } = props;

  useEffect(() => {
    if (mount && location.pathname != "/")
      GET(Constants.API_REPORTS_REQUESTS)
        .then((response) => {
          setReportsLength(response.data.count);
        })
        .catch(() => {
          cogoToast.error(t("error500.general"));
        });
  }, [location]);

  useEffect(
    () => () => {
      setMount(false);
    },
    []
  );

  const signOff = (e) => {
    e.preventDefault();
    logout();
  };

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center background-primary">
        <Link className="navbar-brand brand-logo mx-5" to={PATHS.HOME_PATH}>
          <img className="img-fluid" src={logo} alt="logo" />
        </Link>
        <Link className="navbar-brand brand-logo-mini" to={PATHS.HOME_PATH}>
          <img className="img-fluid" src={logo_small} alt="logo" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <i className="ti-layout-grid2"></i>
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <i className="fa fa-bell menu-icon"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                <Dropdown.Item
                  className="dropdown-item preview-item"
                  as={Link}
                  to={PATHS.REPORT}
                >
                  <div className="d-flex align-items-center">
                    <i className="fa fa-file-archive-o text-primary"></i>
                    <span className="pl-2">
                      {reportsLength
                        ? `Tienes ${reportsLength} reportes pendientes`
                        : "...Cargando"}
                    </span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>

          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <i className="fa fa-user-circle menu-icon"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                <Dropdown.Item
                  className="dropdown-item preview-item"
                  as={Link}
                  to={PATHS.BILLING_INFORMATION_PATH}
                >
                  <div className="d-flex align-items-center">
                    <i className="ti-settings text-primary"></i>
                    <span className="pl-2">{t("navbar.dropdown.perfil")}</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item"
                  onClick={signOff}
                >
                  <div className="d-flex align-items-center">
                    <i className="ti-power-off text-primary"></i>
                    <span className="pl-2">{t("navbar.dropdown.salir")}</span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <i className="ti-layout-grid2"></i>
        </button>
      </div>
    </nav>
  );
}

export default withRouter(withTranslation()(Navbar));
