import React, { Component } from "react";
import { withTranslation } from "react-i18next";
class Footer extends Component {
  render() {
    const { t } = this.props;
    return (
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
            Copyright © 2021{" "}
            <a
              href="https://www.ticsocial.com.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              TICsocial
            </a>
            . {t("general.derechos.reservados")}
          </span>
          <span className="text-muted float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
            TicSocial
          </span>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer);
