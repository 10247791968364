import PropTypes from "prop-types";

export const inputValidation = (value, type) => {
  let regex;
  if (!value || value === "" || !type || type === "") {
    return false;
  }
  switch (type) {
    case "password":
      regex = RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()·_+\-=\[\]{};':"\\|,\.<>\/?¡¿¨´–ºª])[A-Za-zÀ-ÖØ-öø-ÿ\d~!@#$%^&*()_+\-=\[\]{};':"\\|,\.·<>\/?¡¿¨´–ºª]{8,15}$/
      );
      break;
    case "email":
      regex = RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      break;
    case "url":
      regex = RegExp(
        /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/
      );
      break;
    default:
      return false;
  }
  return regex.test(value);
};

inputValidation.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
